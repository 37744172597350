
function Loading() {
    return ( 
        <div style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }}>
            <h1
            style={{fontSize:"80pt"}}
            >Loading...</h1>
        </div>
     );
}

export default Loading;